import { ApolloClient, gql } from '@apollo/client'
import kebabCase from 'lodash/kebabCase'

export async function findEpisodeGuidBySlug(
  client: ApolloClient<object>,
  slug: string,
  projectSlug: string,
): Promise<string | undefined> {
  const { episodes } = await buildEpisodeGuidMap(client, projectSlug)
  return (
    episodes[slug] ||
    Object.entries(episodes).find(([key]) => {
      return slug.indexOf(key) > -1
    })?.[1]
  )
}

async function buildEpisodeGuidMap(
  client: ApolloClient<unknown>,
  projectSlug: string,
): Promise<{ episodes: Record<string, string> }> {
  const { data } = await client.query({ query: getAllEpisodes, variables: { slug: projectSlug } })
  const episodes = {} as Record<string, string>

  data.project.seasons.forEach((season: Season) => {
    season.episodes.forEach((episode) => {
      const seasonName = season.name
      const { name: episodeName, subtitle, guid } = episode

      episodes[getEpisodePathSlug({ seasonName, episodeName })] = guid
      episodes[getFriendlyEpisodePathSlug({ seasonName, episodeName, subtitle })] = guid
    })
  })

  return { episodes }
}

export const getAllEpisodes = gql`
  query getAllEpisodeGuids($slug: String!) {
    project(slug: $slug) {
      seasons {
        name
        episodes {
          subtitle
          name
          guid
        }
      }
    }
  }
`

interface Season {
  name: string
  seasonNumber: number
  episodes: EpisodeIdentifiers[]
}

interface GetFriendlyEpisodePathSlug {
  seasonName: string
  episodeName: string
  subtitle: string
}

export function getFriendlyEpisodePathSlug({ seasonName, episodeName, subtitle }: GetFriendlyEpisodePathSlug): string {
  return kebabCase(`${getEpisodePathSlug({ seasonName, episodeName })} ${subtitle}`)
}

interface GetEpisodePathSlug {
  seasonName: string
  episodeName: string
}

export function getEpisodePathSlug({ seasonName, episodeName }: GetEpisodePathSlug): string {
  return kebabCase(`${seasonName} ${episodeName}`)
}

export interface EpisodeIdentifiers {
  name: string
  guid: string
  subtitle: string
}
